import * as React from "react";
import HaveYouSeenSection from "../../../components/HaveYouSeenSection/HaveYouSeenSection";
import Layout from "../../../components/Layout/Layout";
import TeamRolesSection from "../../../components/TeamRolesSection/TeamRolesSection";
import TestimonialSection from "../../../components/TestimonialSection/TestimonialSection";
import SubPageTitle from "../../../components/SubPageTitle/SubPageTitle";
import { headOffice } from "../../../components/SubPageTitle/subPageTitleContent";
import { headOfficeRoles } from "../../../components/TeamRolesSection/TeamRolesSectionContent";
import { SEO } from "../../../components/seo";
import { descriptions } from "../../../libs/seoContent";

const HeadOffice = () => {
  return (
    <Layout>
      <SubPageTitle content={headOffice} />
      <TeamRolesSection content={headOfficeRoles} />
      <TestimonialSection pageTitle={"Head Office"} className="reduced-padding-bottom" />
      <HaveYouSeenSection page="headOffice" />
    </Layout>
  );
};

export default HeadOffice;

export const Head = () => <SEO title="Head Office" description={descriptions.support} />;
